import { KeyValuePairs } from 'simple-core-ui'
import s from './InfoCard.scss'

interface Props {
  title: string
  info: Array<{
    key: JSX.Element | string
    value: JSX.Element | string
    keyClass?: string
    valueClass?: string
  }>
}

const pairStyles = {
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '20px',
  gridTemplateColumns: '420px 420px'
}

const InfoCard = ({ title, info }: Props) => {
  return (
    <div className={s.container}>
      <p className={s.title}>{title}</p>
      <KeyValuePairs pairs={info} style={pairStyles} />
    </div>
  )
}

export default InfoCard
