import {
  HistoryActionItemAPI,
  HistoryDataAPI,
  TimekeeperHistoryInfo,
  TimekeeperProfileDetailsRatesAPI
} from 'timekeepers/types'
import s from './History.scss'
import { FlatTable } from 'timekeepers/common'
import { HISTORY_NEW_RATES_SUBMISSION_ACTION_COLUMNS } from '../tableDefinitions'
import format from 'date-fns/format'
import pluralize from 'pluralize'
import { formatAttribute, formatValue } from 'timekeepers/helpers'

interface Props {
  data: TimekeeperHistoryInfo[]
}

const History = ({ data }: Props) => {
  return (
    <section className={s.tabContainer}>
      <p className={s.header}>
        Here is the log history for this timekeeper, with the most recent information displayed
        first.
      </p>
      <div>
        {data.map(item => {
          const isDataArray = Array.isArray(item.data)
          const historyData = isDataArray
            ? (item.data as HistoryDataAPI[])
            : [item.data as HistoryActionItemAPI]
          const firstDataItem = historyData[0]
          const actorName = firstDataItem.sl_user
            ? `${firstDataItem.sl_user.name} (${firstDataItem.sl_user.email})`
            : firstDataItem.cg_user_name
          const formattedDate = format(new Date(item.created_date), 'MMM d, yyyy HH:mm aaa')

          return isDataArray && (item.data as HistoryDataAPI[]).length ? (
            <div key={item.created_date} className={s.historyItem}>
              <p className={s.historyItem}>
                <span className={s.actor}>{actorName}</span> submitted:
                <span className={s.date}>{formattedDate}</span>
              </p>
              <ul className={s.list}>
                {historyData.map((dataItem, i) => (
                  <li key={i}>
                    {dataItem.rates?.length
                      ? `${dataItem.action === 'created' ? 'Submitted' : 'Updated'} ${
                          dataItem.rates?.length
                        } new ${pluralize('rate', dataItem.rates?.length)}:`
                      : `${
                          dataItem.action === 'created' ? 'Submitted' : 'Updated'
                        } ${formatAttribute(dataItem.attribute)} ${
                          dataItem.from_value ? dataItem.from_value : ''
                        } ${dataItem.to_value ? 'to ' + dataItem.to_value : ''}`}
                    {'.'}
                    {dataItem.rates?.length && (
                      <FlatTable
                        columns={HISTORY_NEW_RATES_SUBMISSION_ACTION_COLUMNS}
                        rows={dataItem.rates as TimekeeperProfileDetailsRatesAPI[]}
                        className={s.ratesTable}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div key={item.created_date} className={s.historyItem}>
              <p className={s.historyItem}>
                <span className={s.actor}>{actorName}</span>{' '}
                {(item.data as HistoryActionItemAPI).action}{' '}
                {(item.data as HistoryActionItemAPI).attribute} from{' '}
                {formatValue((item.data as HistoryActionItemAPI).from_value as string)} to{' '}
                {formatValue((item.data as HistoryActionItemAPI).to_value as string)}
                {'.'}
                <span className={s.date}>
                  {format(new Date(item.created_date), 'MMM d, yyyy HH:mm aaa')}
                </span>
              </p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default History
