import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io'

import s from './SortIcons.scss'

interface IconProps {
  isActive: boolean
  Icon: React.ComponentType
}

const Icon = ({ isActive, Icon }: IconProps) => (
  <span className={isActive ? s.activeSortIcon : s.sortIcon}>
    <Icon />
  </span>
)

interface Props {
  isDesc?: boolean
}

const SortIcons = ({ isDesc }: Props) => (
  <span className={s.sortIconsContainer}>
    <Icon isActive={typeof isDesc === 'boolean' && !isDesc} Icon={IoIosArrowUp} />
    <Icon isActive={typeof isDesc === 'boolean' && isDesc} Icon={IoIosArrowDown} />
  </span>
)

export default SortIcons
