import React, { Component } from 'react'
import { connect } from 'react-redux'
import swal from 'sweetalert'
import Header from '../components/Header'
import { Loading } from 'components'
import Footer from '../components/Footer'
import SurveyDescription from '../components/SurveyDescription'
import AnswersContainer from './AnswersContainer'
import s from '../styles/VendorEval.scss'
import { withSweetAlert } from 'hocs'
import Media from 'react-media'
import ACT from 'vendors/survey/actions'
import filter from 'lodash/filter'
import { Page403 } from 'simple-core-ui'
import { withRouter } from 'simple-core-ui/hocs'

const isEvalValid = evaluation => {
  const invalidAnswers = filter(evaluation.answers, ans => ans.required && !ans.value)
  return invalidAnswers.length === 0
}
@withRouter
@connect(({ vendorEvals }, ownProps) => {
  const { evaluation, permissionDenied } = vendorEvals
  const isValid = isEvalValid(evaluation)
  const { evalId } = ownProps.router.params

  return {
    evaluation,
    isValid,
    evalId,
    permissionDenied
  }
})
class VendorEvalContainer extends Component {
  state = { isValid: false, headerHeight: 0 }
  headerHeight = React.createRef()

  componentDidMount() {
    const { evalId, dispatch, router } = this.props
    dispatch({
      type: ACT.FETCH_VENDOR_EVAL_REQUESTED,
      payload: {
        evalId,
        navigate: router.navigate
      }
    })
  }

  componentDidUpdate() {
    this.setHeaderHeight()
  }

  cancelSurvey = () => {
    window.location = `/`
  }

  returnToMatter = () => {
    const { evaluation } = this.props
    window.location = `/manage/matters/v2/${evaluation.matterId}`
  }

  saveSurvey = () => {
    const { evaluation, evalId } = this.props
    this.props.dispatch({
      type: ACT.SAVE_VENDOR_EVAL_REQUESTED,
      payload: {
        evaluation,
        evalId
      }
    })
  }

  submitSurvey = async () => {
    const { evaluation, evalId } = this.props

    const willSubmit = await swal({
      title: 'Are you sure?',
      text: "Once you submit this evaluation, you won't be able to edit it",
      buttons: ['No', 'Yes'],
      icon: 'warning'
    })

    if (willSubmit) {
      this.props.dispatch({
        type: ACT.SUBMIT_VENDOR_EVAL_REQUESTED,
        payload: {
          evaluation,
          evalId
        }
      })

      const sweetAlertFactory = withSweetAlert()
      sweetAlertFactory(
        {
          title: 'Thank you for your feedback.',
          buttons: ['Cancel', 'OK'],
          icon: 'success'
        },
        () => {
          if (evaluation.nextSurvey) {
            window.location = `${evaluation.nextSurvey}/`
          } else {
            window.location = '/'
          }
        },
        { cancelConfirmation: false, okConfirmation: false }
      )
    }
  }

  setHeaderHeight = () => {
    const headerHeight = this.headerHeight.current ? this.headerHeight.current.clientHeight : 100
    if (this.state.headerHeight !== headerHeight) {
      this.setState({
        headerHeight
      })
    }
  }

  getWrapperStyle = matches => {
    return {
      paddingTop: matches ? this.state.headerHeight + 'px' : 0
    }
  }

  render() {
    const { evaluation, isValid, permissionDenied } = this.props
    const { matterName, vendorName, startDate, endDate, info, isClosed, userName } = evaluation
    if (permissionDenied) {
      return <Page403 flag="page" />
    }
    if (!evaluation.answers.length) {
      return <Loading />
    }
    return (
      <section className={s.container}>
        <Header
          headerHeight={this.headerHeight}
          matterName={matterName}
          vendorName={vendorName}
          startDate={startDate}
          endDate={endDate}
          info={info}
        />
        <Media query="(max-width: 700px)">
          {matches => (
            <div className={s.answersWrapper} style={this.getWrapperStyle(matches)}>
              <SurveyDescription userName={userName} />
              <AnswersContainer matterName={matterName} vendorName={vendorName} />
              <Footer
                saveSurvey={this.saveSurvey}
                submitSurvey={this.submitSurvey}
                cancelSurvey={this.cancelSurvey}
                returnToMatter={this.returnToMatter}
                isClosed={isClosed}
                isValid={isValid}
              />
            </div>
          )}
        </Media>
      </section>
    )
  }
}

export default VendorEvalContainer
