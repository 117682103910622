import { useState, useEffect, useMemo } from 'react'
import {
  DataTableWrapper,
  Modal,
  ButtonDropdown,
  withConfirmation,
  Ellipsis,
  Internal,
  External,
  ModalContainer
} from 'simple-core-ui'
import { validateLegacyBudget } from 'budgets/forms/validators'
import BHPill from 'budgets/detail_v2/components/BHPill'
import { ActionsPopover } from '../components/ActionsPopover'
import { EditBudgetModal } from '../components/EditBudgetModal'
import { useSelector, useDispatch } from 'react-redux'
import { useMatterGroupLabels } from 'hooks/selectors'

import { CreateBudgetForm } from 'budgets/forms'
import { getBudgetV2Columns, categories } from '../tableDefinitions'
import Filters from './Filters'
import ACT from 'budgets/actions'
import { BULK_ACTIONS, BULK_WARNING_MESSAGES, DIALOG_BULK_ACTIONS } from '../constants'
import { BulkEditBudgetModal } from '../components/BulkEditBudgetModal'
import swal from 'sweetalert'

const initialModalState = {
  type: null,
  isOpen: false,
  pk: null
}

const DELETE_CONF = {
  title: 'You are deleting a budget',
  text: 'Do you wish to continue?'
}

const ARCHIVE_CONF = {
  title: 'You are archiving a budget',
  text: 'Do you wish to continue?'
}

const REVERT_CONF = {
  title: 'You are reverting a budget',
  text:
    'The budget will go back to the status it had last - this can be either draft or approved status. Do you wish to continue?'
}

const APPROVE_CONF = {
  title: 'You are approving a budget',
  text: 'Do you wish to continue?'
}

const DRAFT_CONF = {
  title: 'You are moving a budget to Draft',
  text: 'Do you wish to continue?'
}

const BudgetsListContainer = () => {
  const dispatch = useDispatch()
  const createForm = useSelector(state => state.budgets.createForm)
  const filters = useSelector(state => state.budgets.filters)

  const { rows: rowsList = [], filteredEntries = 0 } = useSelector(
    state => state.budgets.activeBudgets
  )
  const isLoading = useSelector(({ app }) => app?.loading?.ACTIVE_BUDGETS_LIST_FETCH)
  const hasInitialLoad = useSelector(({ app }) => app?.initialLoad?.ACTIVE_BUDGETS_LIST_FETCH)
  const matterGroupLabels = useMatterGroupLabels()

  const [allRowsSelected, setAllRowsSelected] = useState(false)
  const [modalBudget, setModalBudget] = useState(initialModalState)
  const [modalBudgetBulk, setModalBudgetBulk] = useState(initialModalState)
  const [selectedRows, setSelectedRows] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [reset, setReset] = useState(0)
  const [matterGroupValue, setMatterGroupValue] = useState(null)
  const [vendorValue, setVendorValue] = useState(null)
  const [matterValue, setMatterValue] = useState(null)
  const [costCodeValue, setCostCodeValueValue] = useState(null)

  //Bulk edit modal form values
  const [newVendor, setNewVendor] = useState(null)
  const [newMatter, setNewMatter] = useState(null)
  const [newPracticeArea, setNewPracticeArea] = useState(null)
  const [newCostCode, setNewCostCode] = useState(null)
  const [selectedFieldsLength, setSelectedFieldsLength] = useState(0)

  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false)
  const [isDraftModalOpen, setIsDraftModalOpen] = useState(false)
  const [selectedBudget, setSelectedBudget] = useState(null)

  const canLockBudgets = () => dispatch({ type: ACT.LOCK_STATE_FETCH_REQUESTED })

  useEffect(() => {
    if (reset !== 0) {
      const { pageSize, ordering, page, search, category, reset } = filters
      dispatch({
        type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: { params: { pageSize, ordering, page, search, category, reset } }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset])

  useEffect(() => {
    if (!hasInitialLoad) {
      canLockBudgets()
      dispatch({
        type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
        loadingLock: 'on',
        payload: { params: filters }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteBudget = budget => {
    setIsDeleteModalOpen(() => !!budget)
    setSelectedBudget(budget)
  }

  const deleteBudgetConfirm = () => {
    dispatch({
      type: ACT.DELETE_BUDGET_REQUESTED,
      payload: selectedBudget
    })
    setIsDeleteModalOpen(false)
  }

  const archiveBudget = budget => {
    setIsArchiveModalOpen(() => !!budget)
    setSelectedBudget(budget)
  }

  const archiveBudgetConfirm = () => {
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUESTED,
      payload: { status: 'Archived', pk: selectedBudget }
    })
    setIsArchiveModalOpen(false)
  }

  const revertBudget = budget => {
    setIsRevertModalOpen(() => !!budget)
    setSelectedBudget(budget)
  }

  const revertBudgetConfirm = () => {
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUESTED,
      payload: { status: 'Revert', pk: selectedBudget }
    })
    setIsRevertModalOpen(false)
  }

  const approveBudget = budget => {
    setIsApproveModalOpen(() => !!budget)
    setSelectedBudget(budget)
  }

  const approveBudgetConfirm = () => {
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUESTED,
      payload: { status: 'Approved', pk: selectedBudget }
    })
    setIsApproveModalOpen(false)
  }

  const matterBudget = budget => {
    const row = rows.find(row => row.id === budget)
    const value = row?.matterName ? { value: row.matterId, label: row.matterName } : null
    setMatterValue(value)
    setModalBudget({ isOpen: true, type: 'matter', isBulkEdit: false, pk: budget })
  }

  const vendorBudget = budget => {
    const row = rows.find(row => row.id === budget)
    const value = row?.vendorName ? { value: row.vendorId, label: row.vendorName } : null
    setVendorValue(value)
    setModalBudget({ isOpen: true, type: 'vendor', isBulkEdit: false, pk: budget })
  }
  const mattergroupBudget = budget => {
    const row = rows.find(row => row.id === budget)
    const value = row?.mattergroupName
      ? { value: row.mattergroupId, label: row.mattergroupName }
      : null
    setMatterGroupValue(value)
    setModalBudget({ isOpen: true, type: 'mattergroup', isBulkEdit: false, pk: budget })
  }

  const costcodeBudget = budget => {
    const row = rows.find(row => row.id === budget)
    const value = row?.costcodeName ? { value: row.costcodeId, label: row.costcodeName } : null
    setCostCodeValueValue(value)
    setModalBudget({ isOpen: true, type: 'costcode', isBulkEdit: false, pk: budget })
  }

  const draftBudgetConfirm = () => {
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUESTED,
      payload: { status: 'Draft', pk: selectedBudget }
    })
    setIsDraftModalOpen(false)
  }

  const draftBudget = budget => {
    setIsDraftModalOpen(() => !!budget)
    setSelectedBudget(budget)
  }

  const selectRow = ({ id }) => {
    setSelectedRows(prevSelectedRows => {
      const index = prevSelectedRows.indexOf(id)
      if (index !== -1) {
        const newSelectedRows = [...prevSelectedRows]
        newSelectedRows.splice(index, 1)
        return newSelectedRows
      } else {
        return [...prevSelectedRows, id]
      }
    })
  }

  const selectAllRows = () => {
    setAllRowsSelected(allRowsSelected => !allRowsSelected)
    if (allRowsSelected) {
      setSelectedRows([])
    } else {
      setSelectedRows(rows.filter(r => r.canEdit !== false).map(x => x.id))
    }
  }

  const submitNewBudget = () => {
    const { isError, errors } = validateLegacyBudget(createForm)
    if (isError) {
      dispatch({
        type: ACT.UPDATE_BUDGET_REQUEST_FORM,
        payload: {
          errors
        }
      })
    } else {
      dispatch({
        type: ACT.SUBMIT_BUDGET_REQUESTED,
        loadingLock: 'on',
        payload: {
          ...createForm,
          requested: false
        }
      })
      setIsModalOpen(false)
    }
  }

  const updateTable = tableOpts => {
    dispatch({
      type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: { params: { ...filters, ...tableOpts } }
    })
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const applyBulkEditChanges = async () => {
    const willApprove = await swal({
      title: `You are updating ${selectedRows.length} selected ${
        selectedRows.length === 1 ? 'budget' : 'budgets'
      }.`,
      text: 'Are you sure you want to update?',
      buttons: ['Cancel', 'Yes'],
      icon: 'warning'
    })

    if (willApprove) {
      const bulkValues = {
        matter: newMatter,
        vendor: newVendor,
        matter_group: newPracticeArea,
        cost_code: newCostCode
      }

      for (const property in bulkValues) {
        if (bulkValues[property]) {
          dispatch({
            type: ACT.BULK_UPDATE_BUDGET_REQUESTED,
            payload: {
              action: property,
              action_val: bulkValues[property],
              budget_ids: selectedRows
            }
          })
        }
      }
      setModalBudgetBulk({ ...modalBudgetBulk, isOpen: false })
    }
  }

  const applyEditChanges = () => {
    const payload = {
      ...(modalBudget.type === 'matter' ? { matter: matterValue } : {}),
      ...(modalBudget.type === 'vendor' ? { vendor: vendorValue } : {}),
      ...(modalBudget.type === 'mattergroup' ? { mattergroup: matterGroupValue } : {}),
      ...(modalBudget.type === 'costcode' ? { costcode: costCodeValue } : {}),
      pk: modalBudget.pk
    }
    dispatch({
      type: ACT.UPDATE_BUDGET_REQUESTED,
      payload
    })
    setModalBudget(initialModalState)
  }

  const renderCustomAction = row => {
    const isExternal = row.external
    const isLocked = row.lockDate
    const canEdit = row.canEdit
    const category = filters.category
    if (category === 'archived' && isExternal) return false

    return (
      <ActionsPopover
        rowId={row.id}
        isExternal={isExternal}
        category={category}
        deleteBudget={deleteBudget}
        archiveBudget={archiveBudget}
        areLocked={isLocked}
        matterBudget={matterBudget}
        revertBudget={revertBudget}
        approveBudget={approveBudget}
        vendorBudget={vendorBudget}
        mattergroupBudget={mattergroupBudget}
        costcodeBudget={costcodeBudget}
        matterGroupLabels={matterGroupLabels}
        draftBudget={draftBudget}
        canEdit={canEdit}
      />
    )
  }

  const handleFilters = filterObj => {
    dispatch({
      type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
      loadingLock: 'on',
      payload: { params: { ...filters, ...filterObj } }
    })
    setSelectedRows([])
    setAllRowsSelected(false)
  }

  const handleBulkActionSelect = option => {
    const isDialog = DIALOG_BULK_ACTIONS.some(x => x === option.key)
    if (isDialog) {
      withConfirmation(
        () => {
          if (option.key === 'delete') {
            selectedRows.forEach((rowId, index) => {
              dispatch({
                type: ACT.DELETE_BUDGET_REQUESTED,
                payload: rowId,
                counts: { total: selectedRows.length, current: index + 1 }
              })
            })
            dispatch({
              type: ACT.ACTIVE_BUDGETS_LIST_FETCH_REQUESTED,
              loadingLock: 'on',
              payload: { params: filters }
            })
          } else {
            dispatch({
              type: ACT.BULK_UPDATE_BUDGET_REQUESTED,
              payload: {
                action: 'status',
                action_val: option.key,
                budget_ids: selectedRows
              }
            })
          }
          setSelectedRows([])
          setAllRowsSelected(false)
        },
        {
          buttons: ['Cancel', option.key === 'delete' ? 'Yes' : 'Update'],
          title: BULK_WARNING_MESSAGES(selectedRows.length)[option.key],
          ...(option.key === 'revert'
            ? {
                text:
                  'The budget will go back to the status it had last - this can be either draft or approved status.'
              }
            : {}),
          ...(option.key === 'delete'
            ? {
                text: 'Are you sure you want to delete?'
              }
            : {})
        }
      )()
    } else {
      initiateBulkModal({
        type: option.key,
        isOpen: true,
        pk: selectedRows
      })
    }
  }

  const rows = useMemo(() => {
    return rowsList.map(
      ({
        id,
        budgetName,
        budgetUrl,
        matterName,
        vendorName,
        mattergroupName,
        matterleadName,
        costcodeName,
        budgetType,
        budgetAmount,
        startDate,
        endDate,
        remainingTotal,
        external,
        lockDate,
        canEdit
      }) => ({
        id,
        canEdit,
        external,
        lockDate,
        cells: [
          {
            columnKey: 'budgetName',
            content: (
              <a href={budgetUrl}>
                <Ellipsis>{budgetName || '---'}</Ellipsis>
              </a>
            )
          },
          {
            content: <Ellipsis>{matterName || '---'}</Ellipsis>,
            columnKey: 'budgetMatter'
          },
          {
            content: <Ellipsis>{matterleadName || '---'}</Ellipsis>,
            columnKey: 'matterleadName'
          },
          {
            content: <Ellipsis>{vendorName || '---'}</Ellipsis>,
            columnKey: 'vendorName'
          },
          {
            content: <Ellipsis>{mattergroupName || '---'}</Ellipsis>,
            columnKey: 'mattergroupName'
          },
          {
            content: <Ellipsis>{costcodeName || '---'}</Ellipsis>,
            columnKey: 'costcodeName'
          },
          {
            content: <Ellipsis>{budgetType || '---'}</Ellipsis>,
            columnKey: 'budgetType'
          },
          {
            content: <Ellipsis>{startDate || '---'}</Ellipsis>,
            columnKey: 'budgetStartDate'
          },
          {
            content: <Ellipsis>{endDate || '---'}</Ellipsis>,
            columnKey: 'budgetEndDate'
          },
          {
            content: <Ellipsis>{budgetAmount || '---'}</Ellipsis>,
            columnKey: 'amounts'
          },
          {
            content: external ? (
              <img src={External} alt="External" data-tip="External" />
            ) : (
              <img src={Internal} alt="Internal" data-tip="Internal" />
            ),
            columnKey: 'external'
          },
          {
            content: <BHPill isInBudget={remainingTotal >= 0} />,
            columnKey: 'budgetHealth'
          }
        ]
      })
    )
  }, [rowsList])

  const budgetV2Columns = useMemo(() => {
    return getBudgetV2Columns(matterGroupLabels)
  }, [matterGroupLabels])

  const initiateBulkModal = params => {
    setNewVendor(null)
    setNewMatter(null)
    setNewPracticeArea(null)
    setNewCostCode(null)
    setModalBudgetBulk(params)
  }

  const commonModalProps = {
    size: 'sm',
    cancelText: 'Cancel',
    confirmText: 'Yes',
    wrapperStyles: { maxWidth: 400 },
    contentStyle: { minHeight: 0 },
    hasNewButtons: true
  }

  return (
    <div>
      <DataTableWrapper
        title="Budgets"
        rows={rows}
        initialValues={filters}
        bulkDismiss
        selectAllRows={selectAllRows}
        allRowsSelected={allRowsSelected}
        selectRow={selectRow}
        isLoading={isLoading}
        hasActions
        hasTooltip
        remotePagination
        customAction={renderCustomAction}
        selectedRows={new Set(selectedRows)}
        totalEntries={filteredEntries}
        params={filters}
        filteredTotal={filteredEntries}
        categories={categories}
        columns={budgetV2Columns}
        updateTable={updateTable}
        bulkActions={
          <ButtonDropdown
            value={null}
            options={BULK_ACTIONS[filters.category]}
            onSelect={handleBulkActionSelect}
            displayText={`Bulk Actions (${selectedRows.length})`}
            theme="eb"
          />
        }
        filters={
          <Filters
            handleFilters={handleFilters}
            reset={reset}
            hasConfirmation={selectedRows.length}
            setReset={setReset}
            setIsModalOpen={setIsModalOpen}
          />
        }
      />
      <Modal
        title="Add Budget"
        confirmText="Submit"
        cancelText="Cancel"
        size="md"
        content={<CreateBudgetForm />}
        isVisible={isModalOpen}
        confirmCb={submitNewBudget}
        cancelCb={() => setIsModalOpen(false)}
      />
      <Modal
        title={'Edit Budget'}
        confirmText="Update"
        cancelText="Cancel"
        size="md"
        content={
          <EditBudgetModal
            matterGroupLabels={matterGroupLabels}
            modalType={modalBudget.type}
            isBulkEdit={modalBudget.isBulkEdit}
            matterGroupValue={matterGroupValue}
            vendorValue={vendorValue}
            setMatterGroupValue={setMatterGroupValue}
            setVendorValue={setVendorValue}
            costCodeValue={costCodeValue}
            setCostCodeValueValue={setCostCodeValueValue}
            setMatterValue={setMatterValue}
            matterValue={matterValue}
          />
        }
        isVisible={modalBudget.isOpen}
        confirmCb={applyEditChanges}
        cancelCb={() => setModalBudget(initialModalState)}
        hasNewButtons
      />
      <Modal
        title={'Edit Budgets'}
        confirmText="Update"
        cancelText="Cancel"
        size="md"
        content={
          <BulkEditBudgetModal
            matterGroupLabels={matterGroupLabels}
            modalType={modalBudgetBulk.type}
            newVendor={newVendor}
            newMatter={newMatter}
            newPracticeArea={newPracticeArea}
            newCostCode={newCostCode}
            setNewVendor={setNewVendor}
            setNewMatter={setNewMatter}
            setNewPracticeArea={setNewPracticeArea}
            setNewCostCode={setNewCostCode}
            selectedFieldsLength={selectedFieldsLength}
            setSelectedFieldsLength={setSelectedFieldsLength}
          />
        }
        isVisible={modalBudgetBulk.isOpen}
        confirmCb={applyBulkEditChanges}
        cancelCb={() => setModalBudgetBulk(initialModalState)}
      />
      {isArchiveModalOpen && (
        <ModalContainer
          {...commonModalProps}
          title={ARCHIVE_CONF.title}
          content={ARCHIVE_CONF.text}
          cancelCb={() => {
            setIsArchiveModalOpen(false)
          }}
          confirmCb={archiveBudgetConfirm}
        />
      )}
      {isDeleteModalOpen && (
        <ModalContainer
          {...commonModalProps}
          title={DELETE_CONF.title}
          content={DELETE_CONF.text}
          cancelCb={() => setIsDeleteModalOpen(false)}
          confirmCb={deleteBudgetConfirm}
        />
      )}
      {isApproveModalOpen && (
        <ModalContainer
          {...commonModalProps}
          title={APPROVE_CONF.title}
          content={APPROVE_CONF.text}
          cancelCb={() => setIsApproveModalOpen(false)}
          confirmCb={approveBudgetConfirm}
        />
      )}
      {isRevertModalOpen && (
        <ModalContainer
          {...commonModalProps}
          title={REVERT_CONF.title}
          content={REVERT_CONF.text}
          cancelCb={() => setIsRevertModalOpen(false)}
          confirmCb={revertBudgetConfirm}
        />
      )}
      {isDraftModalOpen && (
        <ModalContainer
          {...commonModalProps}
          title={DRAFT_CONF.title}
          content={DRAFT_CONF.text}
          cancelCb={() => setIsDraftModalOpen(false)}
          confirmCb={draftBudgetConfirm}
          titleStyles={{ fontSize: 21 }}
        />
      )}
    </div>
  )
}

export default BudgetsListContainer
