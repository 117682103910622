import { AiOutlineInfoCircle } from 'react-icons/ai'
import ReactTooltip from 'react-tooltip'
import s from './TimekeeperProfileDetails.scss'

export const NEW_TK_PENDING_RATES_COLUMNS = [
  { key: 'requested_rate', title: 'Billed Rate', sortable: true },
  { key: 'rack_rate', title: 'Rack Rate', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'type', title: 'Type', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  {
    key: 'class_comparison',
    title: (
      <>
        {'Class Comparison'}
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="classComparison" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="classComparison"
          type="light"
          effect="solid"
          place="top"
          border
        >
          Compare rates across all
          <br /> classifications.
        </ReactTooltip>
      </>
    ),
    sortable: true
  },
  { key: 'actions', title: 'Actions', sortable: false }
]

export const NEW_TK_HISTORICAL_RATES_COLUMNS = [
  { key: 'requested_rate', title: 'Amount', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'classification', title: 'Classification', sortable: true },
  { key: 'status', title: 'Status', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  { key: 'actions', title: 'Actions', sortable: false }
]

export const NEW_TIMEKEEPER_HISTORY_COLUMNS = [
  { key: 'requested_rate', title: 'Billed Rate', sortable: true },
  { key: 'rack_rate', title: 'Rack Rate', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  { key: 'type', title: 'Type', sortable: true },
  { key: 'actions', title: 'Actions', sortable: false }
]

export const EXISTING_TK_PENDING_RATES_COLUMNS = [
  { key: 'requested_rate', title: 'Billed Rate', sortable: true },
  { key: 'rack_rate', title: 'Rack Rate', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'type', title: 'Type', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  {
    key: 'class_comparison',
    title: (
      <>
        {'Class Comparison'}
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="classComparison" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="classComparison"
          type="light"
          effect="solid"
          place="top"
          border
        >
          Compare rates across all
          <br /> classifications.
        </ReactTooltip>
      </>
    ),
    sortable: true
  },
  {
    key: 'percentage_change',
    title: (
      <>
        {'Percentage Change'}
        <AiOutlineInfoCircle className={s.infoTooltip} data-for="percentageChange" data-tip />
        <ReactTooltip
          className={s.tooltip}
          id="percentageChange"
          type="light"
          effect="solid"
          place="top"
          border
        >
          Compare the rate changes to a
          <br /> previously approved rate on file.
        </ReactTooltip>
      </>
    ),
    sortable: true
  },
  { key: 'actions', title: 'Actions', sortable: false }
]

export const EXISTING_TK_HISTORICAL_RATES_COLUMNS = [
  { key: 'requested_rate', title: 'Amount', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'classification', title: 'Classification', sortable: true },
  { key: 'status', title: 'Status', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  { key: 'actions', title: 'Actions', sortable: false }
]

export const HISTORY_NEW_RATES_SUBMISSION_ACTION_COLUMNS = [
  { key: 'requested_rate', title: 'Billed Rate', sortable: true },
  { key: 'rack_rate', title: 'Rack Rate', sortable: true },
  { key: 'currency', title: 'Currency', sortable: true },
  { key: 'effective', title: 'Effective Date', sortable: true },
  { key: 'reason_for_increase', title: 'Request Reason', sortable: false },
  { key: 'type', title: 'Type', sortable: true }
]
