import { IoIosMore } from 'react-icons/io'

import { HoverAction, Popover } from 'simple-core-ui'
import s from './ActionsPopover.scss'
import { TimekeeperRow } from 'timekeepers/types'
import { ReactNode } from 'react'

interface Props {
  actions: {
    name: string
    text: string | ReactNode
    onClick: () => void
  }[]
  timekeeper: TimekeeperRow
}

const ActionsPopover = ({ actions, timekeeper }: Props) => {
  return (
    <Popover
      testId={`item-${timekeeper.timekeeper_file_id}`}
      trigger={
        <HoverAction
          hasNewDesign
          tip="More"
          icon={<IoIosMore />}
          size="small"
          className={s.hoverAction}
        />
      }
      triggerClassName={s.actionsContainer}
      actions={actions}
      border
    />
  )
}

export default ActionsPopover
