import variables from 'styles/_variables.scss'

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    border: `1px solid ${state.isDisabled ? variables.bulkLightGrey1 : variables.textBlack}`
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: state.isDisabled ? variables.bulkLightGrey2 : variables.white
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isDisabled ? variables.bulkLightGrey1 : variables.textBlack
  }),
  placeholder: (provided, state) => ({
    ...provided,
    color: variables.bulkGrey,
    opacity: 0.7
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    color: variables.textBlack
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: variables.textBlack
  })
}

export const modalControlStyles = error => ({
  ...selectStyles,
  control: (provided, state) => ({
    ...provided,
    borderColor: error && !state.hasValue ? variables.bulkRed : variables.textBlack,
    '&:hover': {
      borderColor: error && !state.hasValue ? variables.bulkRed2 : variables.bulkGrey
    }
  })
})

export const modalInputFieldStyles = {
  borderRadius: '4px',
  border: '1px solid $gray1100',
  padding: '0.4rem 1.6rem',
  width: '90%'
}
