export const BULK_OPTIONS = [
  { label: 'Approve', value: 'approve' },
  { label: 'Reject', value: 'reject' },
  { label: 'Edit Effective Date(s)', value: 'edit' }
]

export const TIMEKEEPERS_BASE_URL = '/timekeepers'

export const URLS = {
  TIMEKEEPERS_RATES_COUNT: '/tk_rates_count/',
  TIMEKEEPERS_BATCH_LIST: '/timekeeper_batch_list/',
  TIMEKEEPERS_RATES_LIST: '/timekeeper_rates_list/',
  ALL_TIMEKEEPERS_LIST: '/timekeepers_list/',
  TIMEKEEPER_UPDATE: '/timekeeper_update/'
}

export const EXISTING_TK_TABS = [
  {
    label: 'Rates',
    value: 'rates'
  },
  {
    label: 'Profile',
    value: 'profile'
  },
  {
    label: 'History',
    value: 'history'
  }
]

export const NEW_TK_TABS = [
  {
    label: 'Rates and Requests',
    value: 'rates'
  },
  {
    label: 'History',
    value: 'history'
  }
]
