import { RadioFilter } from 'common/Filters'
import { Input } from 'simple-core-ui'
import st from './Filters.scss'
import Select from 'react-select'
import currencies from 'data/currencies'
import { nullOptions, naOptions } from 'bulk/helpers'
import { selectStyles } from 'bulk/styles/styleSettings'
import variables from 'styles/_variables.scss'

const betweenOptionsWrapperStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  width: '100%'
}

const CurrencyFilter = ({
  reset,
  getFilterLabel,
  curField = { filterOpLabel: null, val1: null, val2: null, val3: null, field: null },
  filter,
  handleFilterApply,
  onChange,
  onClear,
  onCancel,
  hasConfirmation,
  currencyOptWrapperStyle,
  width,
  curSelection,
  curInputSelection,
  getInputValue,
  onFilterInputChange
}) => {
  const disableCurrency = (
    optionValue,
    inputField = { op: null, value1: 0, value2: 0, value3: null }
  ) => {
    const { value1, value2 } = inputField
    const basicFilterOptions = ['is null', 'is not null', 'is n/a', 'is not n/a']

    if (
      !optionValue ||
      basicFilterOptions.includes(optionValue) ||
      (['is', '<=', '>='].includes(optionValue) && value1)
    ) {
      return false
    } else if (optionValue === 'between') {
      return !(value1 && value2) || parseInt(value1) >= parseInt(value2)
    } else {
      return !value1
    }
  }

  const { filterOpLabel, val1, val2, val3, field } = curField
  const filterConfig = { filterName: filter.field, filterType: filter.type }

  return (
    <RadioFilter
      reset={reset}
      isDisabled={disableCurrency(curSelection, curInputSelection)}
      customLabelFn={s =>
        getFilterLabel(filterOpLabel, filter.display_name, val1, val2, val3?.value)
      }
      width={500}
      initialValue={
        field && {
          value: field
        }
      }
      title={filter.display_name}
      onSelect={selectedOpt => {
        handleFilterApply(filterConfig, selectedOpt, 'currency')
      }}
      onChangeCb={e => {
        onChange(e, filter.field)
      }}
      onClear={() => onClear(filter.field)}
      onCancelCb={() => onCancel(filter.field)}
      hasConfirmation={hasConfirmation}
      options={[
        {
          value: 'between',
          renderLabel: props => (
            <div style={currencyOptWrapperStyle}>
              <p style={width}>between</p>{' '}
              <div style={betweenOptionsWrapperStyle}>
                <div style={{ width: 67, marginLeft: 15 }}>
                  <Input
                    type="number"
                    style={curSelection === 'between' ? { borderColor: variables.textBlack } : {}}
                    className={st.textInput}
                    text={
                      curSelection === 'between' && curInputSelection?.op === 'between'
                        ? getInputValue(filter.field, 'between')
                        : ''
                    }
                    onChangeCb={event => {
                      onFilterInputChange(filter.field, 'between', event.target.value)
                    }}
                    isDisabled={curSelection !== 'between'}
                    {...props}
                  />
                </div>
                <p style={{ margin: '0 0 0 15px' }}>and</p>
                <div style={{ width: 67, marginLeft: 15 }}>
                  <Input
                    type="number"
                    style={curSelection === 'between' ? { borderColor: variables.textBlack } : {}}
                    className={st.textInput}
                    text={
                      curSelection === 'between' && curInputSelection?.op === 'between'
                        ? getInputValue(filter.field, 'between', 1)
                        : ''
                    }
                    onChangeCb={event => {
                      onFilterInputChange(filter.field, 'between', null, event.target.value)
                    }}
                    isDisabled={curSelection !== 'between'}
                    {...props}
                  />
                </div>
                <div style={{ width: '30%', marginLeft: 15 }}>
                  <Select
                    options={currencies}
                    value={
                      (curSelection === 'between' &&
                        curInputSelection?.op === 'between' &&
                        getInputValue(filter.field, 'between', 2)) ||
                      ''
                    }
                    isPortal={false}
                    onChange={(value, action) =>
                      onFilterInputChange(filter.field, 'between', null, null, action, value)
                    }
                    isDisabled={curSelection !== 'between'}
                    placeholder="Search currency"
                    styles={selectStyles}
                    {...props}
                  />
                </div>
              </div>
            </div>
          )
        },
        {
          value: '<=',
          renderLabel: props => (
            <div style={currencyOptWrapperStyle}>
              <p style={width}>less than or equal to</p>{' '}
              <div style={{ width: 100 }}>
                <Input
                  type="number"
                  style={curSelection === '<=' ? { borderColor: variables.textBlack } : {}}
                  className={st.textInput}
                  text={
                    curSelection === '<=' && curInputSelection?.op === '<='
                      ? getInputValue(filter.field, '<=')
                      : ''
                  }
                  onChangeCb={event => {
                    onFilterInputChange(filter.field, '<=', event.target.value)
                  }}
                  isDisabled={curSelection !== '<='}
                  {...props}
                />
              </div>
              <div style={{ width: '40%' }}>
                <Select
                  options={currencies}
                  value={
                    (curSelection === '<=' &&
                      curInputSelection?.op === '<=' &&
                      getInputValue(filter.field, '<=', 2)) ||
                    ''
                  }
                  isPortal={false}
                  onChange={(value, action) =>
                    onFilterInputChange(filter.field, '<=', null, null, action, value)
                  }
                  isDisabled={curSelection !== '<='}
                  placeholder="Search currency"
                  styles={selectStyles}
                  {...props}
                />
              </div>
            </div>
          )
        },
        {
          value: '>=',
          renderLabel: props => (
            <div style={currencyOptWrapperStyle}>
              <p style={width}>greater than or equal to</p>{' '}
              <div style={{ width: 100 }}>
                <Input
                  type="number"
                  style={curSelection === '>=' ? { borderColor: variables.textBlack } : {}}
                  className={st.textInput}
                  text={
                    curSelection === '>=' && curInputSelection?.op === '>='
                      ? getInputValue(filter.field, '>=')
                      : ''
                  }
                  onChangeCb={event => {
                    onFilterInputChange(filter.field, '>=', event.target.value)
                  }}
                  isDisabled={curSelection !== '>='}
                  {...props}
                />
              </div>
              <div style={{ width: '40%' }}>
                <Select
                  options={currencies}
                  value={
                    (curSelection === '>=' &&
                      curInputSelection?.op === '>=' &&
                      getInputValue(filter.field, '>=', 2)) ||
                    ''
                  }
                  isPortal={false}
                  onChange={(value, action) =>
                    onFilterInputChange(filter.field, '>=', null, null, action, value)
                  }
                  isDisabled={curSelection !== '>='}
                  placeholder="Search currency"
                  styles={selectStyles}
                  {...props}
                />
              </div>
            </div>
          )
        },
        {
          value: 'is',
          renderLabel: props => (
            <div style={currencyOptWrapperStyle}>
              <p style={width}>equal to</p>{' '}
              <div style={{ width: 100 }}>
                <Input
                  type="number"
                  style={curSelection === 'is' ? { borderColor: variables.textBlack } : {}}
                  className={st.textInput}
                  text={
                    curSelection === 'is' && curInputSelection?.op === 'is'
                      ? getInputValue(filter.field, 'is')
                      : ''
                  }
                  onChangeCb={event => {
                    onFilterInputChange(filter.field, 'is', event.target.value)
                  }}
                  isDisabled={curSelection !== 'is'}
                  {...props}
                />
              </div>
              <div style={{ width: '40%' }}>
                <Select
                  options={currencies}
                  value={
                    (curSelection === 'is' &&
                      curInputSelection?.op === 'is' &&
                      getInputValue(filter.field, 'is', 2)) ||
                    ''
                  }
                  isPortal={false}
                  onChange={(value, action) =>
                    onFilterInputChange(filter.field, 'is', null, null, action, value)
                  }
                  isDisabled={curSelection !== 'is'}
                  placeholder="Search currency"
                  styles={selectStyles}
                  {...props}
                />
              </div>
            </div>
          )
        },
        ...nullOptions,
        ...(filter.na_filter ? naOptions : [])
      ]}
    />
  )
}

export default CurrencyFilter
