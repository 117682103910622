import s from './Tabs.scss'
import cn from 'classnames'
import { Badge } from 'simple-core-ui'

interface Props {
  tabs: { label: string; value: string; count?: number }[]
  selectedTab: string
  setSelectedTab: (tab: string) => void
  listClassName?: string
}

const Tabs = ({ tabs, selectedTab, setSelectedTab, listClassName }: Props) => {
  return (
    <ul className={cn(s.tabs, listClassName)}>
      {tabs.map(tab => (
        <li
          onClick={() => setSelectedTab(tab.value)}
          key={tab.value}
          data-testid={tab.value}
          className={`${s.tab} ${selectedTab === tab.value ? s.selected : ''}`}
        >
          {tab.count ? (
            <Badge asPill className={s.pill} content={String(tab.count)} size="sm" />
          ) : null}
          {selectedTab === tab.value && <hr className={cn(s.selectedLine, s.selectedLineOn)} />}
          {tab.label}
        </li>
      ))}
    </ul>
  )
}

export default Tabs
