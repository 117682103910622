import { format, parseISO } from 'date-fns'

export const formatDate = (dateString: string, dateFormat: string = 'MM/dd/yyyy') => {
  const isoString = dateString
  const dateObject = parseISO(isoString)
  const formattedDate = format(dateObject, dateFormat)
  return formattedDate
}

export const formatValue = (value: string) =>
  value === 'approve' ? 'Approved' : value === 'reject' ? 'Rejected' : 'Pending'

export const formatAttribute = (attribute: string) =>
  attribute
    .replace(/_/g, ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
