import s from './TimekeeperProfileDetails.scss'
import { InfoCard, Tabs } from 'timekeepers/common'
import { useEffect, useMemo, useState } from 'react'
import cn from 'classnames'
import { PiCheckCircleFill, PiXCircleFill } from 'react-icons/pi'
import { TimekeeperHistoryInfo, TimekeeperProfileInfo, TimekeeperRow } from 'timekeepers/types'
import { GoCheckCircle, GoXCircle } from 'react-icons/go'
import { EXISTING_TK_TABS, NEW_TK_TABS, TIMEKEEPERS_BASE_URL, URLS } from 'timekeepers/constants'
import { Updater, useImmer } from 'use-immer'
import { makeGetRequest, makePatchRequest } from 'utils/api'
import { serializeTimekeeperProfileDetails } from 'timekeepers/serializers'
import { useDispatch } from 'react-redux'
import { Button, CharLimitInput, ModalContainer } from 'simple-core-ui'
import { Rates } from './Rates'
import { History } from './History'
import ReactTooltip from 'react-tooltip'
import { format } from 'date-fns'

interface Props {
  timekeeper: TimekeeperRow | null
  setShowTkProfile: (show: boolean) => void
  setShowLoadingSkeleton: (show: boolean) => void
}

const initialProfileDetails = {
  isNew: false,
  pendingRates: [],
  historicalRates: [],
  tkUpdates: [],
  generalInfo: {
    classification: '',
    location: '',
    email: '',
    practiceGroup: '',
    country: '',
    barYear: ''
  },
  diversityInfo: {
    race: '',
    disability: '',
    gender: '',
    lgbtq: '',
    veteran_status: ''
  },
  timekeeperMeta: {
    name: '',
    id: '',
    created: ''
  }
}

const initialHistoryDetails: TimekeeperHistoryInfo[] = [
  {
    created_date: '',
    data: {
      attribute: '',
      action: '',
      from_value: '',
      to_value: '',
      rates: null,
      cg_user_name: null,
      sl_user: {
        name: '',
        email: ''
      }
    }
  }
]

const cardInfoClasses = {
  keyClass: s.infoKey,
  valueClass: s.infoValue,
  sectionClass: s.infoSection
}

const renderValueNode = (value: string) =>
  value ? (
    <div className={s.diversityValue}>
      <GoCheckCircle className={s.diversityIcon} strokeWidth="1.1px" color="#57C787" />
      <span>Provided</span>
    </div>
  ) : (
    <div className={s.diversityValue}>
      <GoXCircle className={s.diversityIcon} strokeWidth="1.1px" color="#F1615F" />
      <span>Not Provided</span>
    </div>
  )

const footerButtonStyles = {
  height: '40px',
  border: '1px solid #d4d4d4',
  backgroundColor: 'white',
  margin: '14px 24px'
}

const TimekeeperProfileDetails = ({
  timekeeper,
  setShowTkProfile,
  setShowLoadingSkeleton
}: Props) => {
  const [selectedTab, setSelectedTab] = useState('rates')
  const [hasCharLimitError, setHasCharLimitError] = useState(false)
  const [showRejectionReason, setShowRejectionReason] = useState(false)
  const [rejectionData, setRejectionData] = useImmer({
    all: false,
    batch_id: timekeeper?.timekeeper_row_id,
    vendor_id: timekeeper?.vendor_id,
    reason: ''
  })
  const [profileDetails, setProfileDetails]: [
    TimekeeperProfileInfo,
    Updater<TimekeeperProfileInfo>
  ] = useImmer<TimekeeperProfileInfo>(initialProfileDetails)
  const [historyDetails, setHistoryDetails]: [
    TimekeeperHistoryInfo[],
    Updater<TimekeeperHistoryInfo[]>
  ] = useImmer<TimekeeperHistoryInfo[]>(initialHistoryDetails)

  const dispatch = useDispatch()

  const fetchTimekeeperProfileDetails = async () => {
    try {
      if (timekeeper) {
        const response = await makeGetRequest(
          `${TIMEKEEPERS_BASE_URL}/${timekeeper.timekeeper_row_id}/?vendor_id=${timekeeper.vendor_id}`
        )

        setProfileDetails(serializeTimekeeperProfileDetails(timekeeper, response))
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  const fetchTimekeeperHistory = async () => {
    try {
      if (timekeeper) {
        const { tk_history } = await makeGetRequest(
          `${TIMEKEEPERS_BASE_URL}/${timekeeper.timekeeper_row_id}/history/`
        )
        setHistoryDetails(tk_history)
      }
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
    }
  }

  useEffect(() => {
    fetchTimekeeperProfileDetails()
    fetchTimekeeperHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeTab = (tab: string) => {
    ReactTooltip.rebuild()
    setSelectedTab(tab)
    setRejectionData(draft => ({ ...draft, all: false, reason: '' }))
  }

  const renderRejectionReasonModalContent = (reason: string) => (
    <>
      <p className={s.modalText}>Are you sure you want to reject the selected rate(s)?</p>
      <p className={s.fieldTitle}>
        Reason for Rejection <span>*</span>
      </p>
      <CharLimitInput
        rows={4}
        type="textarea"
        cssClass={s.textarea}
        value={reason}
        onChangeCb={e => setRejectionData(draft => ({ ...draft, reason: e.target.value }))}
        dynamicCharCalculation
        charMsgSuffix=" characters"
        hasLengthErrorCb={(hasError: boolean) => setHasCharLimitError(hasError)}
      />
    </>
  )

  const generalCardInfo = useMemo(() => {
    return [
      {
        key: 'Classification',
        value: profileDetails.generalInfo.classification,
        ...cardInfoClasses
      },
      { key: 'Location', value: profileDetails.generalInfo.location, ...cardInfoClasses },
      { key: 'Email', value: profileDetails.generalInfo.email, ...cardInfoClasses },
      {
        key: 'Practice Group',
        value: profileDetails.generalInfo.practiceGroup,
        ...cardInfoClasses
      },
      { key: 'Country', value: profileDetails.generalInfo.country, ...cardInfoClasses },
      { key: 'Bar Year', value: profileDetails.generalInfo.barYear, ...cardInfoClasses }
    ]
  }, [profileDetails])

  const diversityCardInfo = useMemo(() => {
    return [
      {
        key: 'Race',
        value: renderValueNode(profileDetails.diversityInfo.race),
        ...cardInfoClasses
      },
      {
        key: 'Disability',
        value: renderValueNode(profileDetails.diversityInfo.gender),
        ...cardInfoClasses
      },
      {
        key: 'Gender',
        value: renderValueNode(profileDetails.diversityInfo.gender),
        ...cardInfoClasses
      },
      {
        key: 'LGBTQ+',
        value: renderValueNode(profileDetails.diversityInfo.lgbtq),
        ...cardInfoClasses
      },
      {
        key: 'Veteran Status',
        value: renderValueNode(profileDetails.diversityInfo.veteran_status),
        ...cardInfoClasses
      }
    ]
  }, [profileDetails])

  const resetState = () => {
    setShowRejectionReason(false)
    setRejectionData(draft => ({ ...draft, all: false, reason: '' }))
  }

  const onConfirmRejection = async (all?: boolean) => {
    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        status: 'reject',
        reason: rejectionData.reason,
        vendor_id: timekeeper?.vendor_id,
        ...(all
          ? { timekeeper: (timekeeper as TimekeeperRow).timekeeper_row_id, all: true }
          : {
              tk_ids: timekeeper?.id,
              batch_id: timekeeper?.timekeeper_file_id
            })
      }

      await makePatchRequest(url, data)
      fetchTimekeeperProfileDetails()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      setShowLoadingSkeleton(true)
    }
    resetState()
    setShowRejectionReason(false)
  }

  const handleApprove = async (row?: TimekeeperRow, all?: boolean) => {
    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        status: 'approve',
        vendor_id: timekeeper?.vendor_id,
        ...(all
          ? { timekeeper: (timekeeper as TimekeeperRow).timekeeper_row_id, all: true }
          : {
              tk_ids: row?.id,
              batch_id: timekeeper?.timekeeper_row_id
            })
      }

      await makePatchRequest(url, data)
      fetchTimekeeperProfileDetails()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      resetState()
    }
  }

  const handleReject = (row?: TimekeeperRow, all?: boolean) => {
    setShowRejectionReason(true)
    setRejectionData(draft => ({ ...draft, all }))
  }

  const handleReset = async (row: TimekeeperRow) => {
    try {
      const url = `${TIMEKEEPERS_BASE_URL}${URLS.TIMEKEEPER_UPDATE}`

      const data = {
        status: '',
        status_reason: '',
        vendor_id: timekeeper?.vendor_id,
        tk_ids: row?.id,
        batch_id: timekeeper?.timekeeper_file_id
      }

      await makePatchRequest(url, data)
      fetchTimekeeperProfileDetails()
    } catch (error) {
      dispatch({
        type: 'API_ERROR',
        error
      })
      resetState()
    }
  }

  const actionButtons = (
    <div className={s.actionsContainer}>
      <div className={s.statusButton} onClick={() => handleApprove(undefined, true)}>
        <PiCheckCircleFill color="#40A674" />
        <span>Approve All</span>
      </div>
      <div className={s.statusButton} onClick={() => handleReject(undefined, true)}>
        <PiXCircleFill color="#D3455B" />
        <span>Reject All</span>
      </div>
    </div>
  )

  return (
    <div className={s.container}>
      <div className={s.header}>
        <p className={s.timekeeperName}>
          {profileDetails.timekeeperMeta.name}
          {profileDetails.isNew ? <span className={s.badge}>New Timekeeper</span> : null}
        </p>
        <p className={s.timekeeperMeta}>
          ID {profileDetails.timekeeperMeta.id}
          <span className={s.bullet}>&bull;</span>
          <span className={s.date}>
            {profileDetails.timekeeperMeta.created
              ? format(new Date(profileDetails.timekeeperMeta.created), 'MM/dd/yyyy')
              : null}
          </span>
        </p>
      </div>
      <div
        className={s.close}
        onClick={() => setShowTkProfile(false)}
        data-testid="tk-profile-close"
      >
        &times;
      </div>
      <Tabs
        listClassName={cn(s.alignLeft, s.tabs)}
        tabs={profileDetails.isNew ? NEW_TK_TABS : EXISTING_TK_TABS}
        selectedTab={selectedTab}
        setSelectedTab={changeTab}
      />
      {selectedTab === 'rates' ? (
        <Rates
          isNewTk={profileDetails.isNew}
          actionButtons={actionButtons}
          profileDetails={profileDetails}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleReset={handleReset}
          generalCardInfo={generalCardInfo}
          diversityCardInfo={diversityCardInfo}
        />
      ) : selectedTab === 'history' ? (
        <History data={historyDetails} />
      ) : (
        <div className={s.existingTkProfileContainer}>
          <p className={s.existingTkProfileHeader}>
            Here are the latest details for this timekeeper.
          </p>
          <InfoCard info={generalCardInfo} title="General Information" />
          <InfoCard info={diversityCardInfo} title="Diversity Information" />
        </div>
      )}
      {showRejectionReason && (
        <ModalContainer
          contentClassName={s.modal}
          title="Reject Timekeeper Rate"
          content={renderRejectionReasonModalContent(rejectionData.reason)}
          confirmText="Yes, Reject"
          size="sm"
          hasNewButtons
          contentStyle={{ padding: '10px 24px 30px', minHeight: 'auto' }}
          confirmCb={onConfirmRejection}
          cancelCb={resetState}
          isDisabled={hasCharLimitError || !rejectionData.reason.trim()}
        />
      )}
      <div className={s.footer}>
        <Button
          isSecondary
          hasNewDesign
          style={footerButtonStyles}
          onClick={() => setShowTkProfile(false)}
        >
          Close
        </Button>
      </div>
    </div>
  )
}

export default TimekeeperProfileDetails
