import { useState, useMemo, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Title, VerticalTabs, VerticalTab } from 'simple-core-ui'
import { TabReportsContainer } from 'tableau'
import s from './Reports.scss'
import { makeGetRequest } from 'utils/api'
import APP_PAGES_MAP from 'data/app_pages_map'
import {
  REPORT_LIST_KEY,
  ADHOC_REPORT_KEY,
  INVOICES_CATEGORIES,
  MATTERS_CATEGORIES,
  VENDORS_CATEGORIES,
  SPEND_CATEGORIES,
  CUSTOM_REPORTS_KEYS,
  TABLEAU_REPORTS,
  ALL_SUPERSET_DASHBOARDS_PAGE_SIZE,
  SIMPLESET_USER_ROLES
} from './constants'
import { hasModule } from 'utils/helpers'
import { MODULE } from 'utils/constants'
import { StandardReports } from './StandardReports'
import { CustomReports } from './CustomReports'
import { AdhocReportsContainer } from './AdhocReports'
import {
  toCustomReportsV2,
  toExportedReports,
  toSavedAdhocReports,
  toSupersetList
} from './serializers'
import ACT from './Superset/actions'
import { HiSparkles } from 'react-icons/hi2'

const tabPath = { standard: 'standard', adhoc: 'adhoc', custom: 'custom', simplechat: 'simplechat' }

const ReportsContainer = () => {
  const navigate = useNavigate()
  const { activeTab } = useParams()
  const dispatch = useDispatch()
  const { simplesetUserAccess, simplesetUserRole } = useSelector(
    state => state.app.credentials.user
  )
  const { navigationUrls } = useSelector(state => state.app.credentials)

  const [isLoading] = useState(false)
  const [customReportsV2, setCustomReportsV2] = useState([])
  const [exportedReports, setExportedReports] = useState([])
  const [savedAdhocReports, setSavedAdhocReports] = useState([])
  const [currentActiveTab, setCurrentActiveTab] = useState(null)
  const { result: supersetDashboards = [] } = useSelector(
    state => state.superset.supersetDashboards
  )
  const isSupersetDashboardsLoading = useSelector(
    state => state.app.loading.SUPERSET_DASHBOARDS_FETCH
  )

  const standardReportsMap = useMemo(() => {
    const urls = navigationUrls.filter(el => el.name === REPORT_LIST_KEY)[0].urls
    const list = urls
      .filter(el => ![ADHOC_REPORT_KEY, TABLEAU_REPORTS, ...CUSTOM_REPORTS_KEYS].includes(el.name))
      .map(el => ({
        ...el,
        ...APP_PAGES_MAP[el.name]
      }))

    return {
      Invoices: list.filter(el => INVOICES_CATEGORIES.includes(el.text)),
      Matters: list.filter(el => MATTERS_CATEGORIES.includes(el.text)),
      Vendors: list.filter(el => VENDORS_CATEGORIES.includes(el.text)),
      Spend: list.filter(el => SPEND_CATEGORIES.includes(el.text))
    }
  }, [navigationUrls])

  const supersetReportsMap = useMemo(() => toSupersetList(supersetDashboards), [supersetDashboards])

  const isTableau = useMemo(() => {
    const urls = navigationUrls.filter(el => el.name === REPORT_LIST_KEY)[0].urls
    return !!urls.find(url => url.name === TABLEAU_REPORTS)
  }, [navigationUrls])

  const isSuperset = useMemo(() => hasModule(MODULE.SIMPLESET_REPORTING) && simplesetUserAccess, [
    simplesetUserAccess
  ])

  useEffect(() => {
    ;(async () => {
      if (hasModule('has_custom_reports_v2')) {
        const response = await makeGetRequest('/reports/v2/custom/json')
        setCustomReportsV2(toCustomReportsV2(response.reports))

        const exportedReportsResponse = await makeGetRequest(
          '/reports/v2/custom/exports/list/json/'
        )
        setExportedReports(toExportedReports(exportedReportsResponse.user_exports))
      }
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      const response = await makeGetRequest('/reports/adhoc/saved/json/')
      setSavedAdhocReports(toSavedAdhocReports(response.saved_reports))
    })()
  }, [])

  const fetchSupersetDashboards = () => {
    dispatch({
      type: ACT.SUPERSET_DASHBOARDS_FETCH_REQUESTED,
      payload: { params: { pageSize: ALL_SUPERSET_DASHBOARDS_PAGE_SIZE } },
      loadingLock: 'on'
    })
  }

  useEffect(() => {
    isSuperset && fetchSupersetDashboards()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperset])

  const changeTab = tab => {
    setCurrentActiveTab(tab)
    navigate(`/v2/reports/${tab}`)
  }

  const openTab = () => {
    window.open(`${window.location.origin}/v2/reports/aichat`, '_blank')
  }

  useEffect(() => {
    setCurrentActiveTab(activeTab || tabPath.standard)
  }, [activeTab])

  return (
    <section className={s.container}>
      <Title text="Reports" rank={2} style={{ margin: 0 }} />
      <section className={s.panel}>
        <VerticalTabs activeTabKey={currentActiveTab} withLoading={isLoading}>
          <VerticalTab key="standard" label="Standard" onClick={() => changeTab(tabPath.standard)}>
            <StandardReports
              reports={standardReportsMap}
              supersetReports={supersetReportsMap.standard}
              loading={isSupersetDashboardsLoading}
            />
          </VerticalTab>
          <VerticalTab key="adhoc" label="Build Your Own" onClick={() => changeTab(tabPath.adhoc)}>
            <AdhocReportsContainer
              savedReports={savedAdhocReports}
              savedSupersetReports={supersetReportsMap.saved}
            />
          </VerticalTab>
          <VerticalTab key="custom" label="Built for You" onClick={() => changeTab(tabPath.custom)}>
            <CustomReports customReportsV2={customReportsV2} exportedReports={exportedReports} />
          </VerticalTab>
          {isTableau ? (
            <VerticalTab key="tableau" label="Tableau">
              <TabReportsContainer />
            </VerticalTab>
          ) : null}
          {hasModule('has_ask_ai') &&
          simplesetUserRole === SIMPLESET_USER_ROLES.create &&
          simplesetUserAccess ? (
            <VerticalTab
              key="simplechat"
              label={
                <span style={{ display: 'flex', gap: 4 }}>
                  AskAI <HiSparkles style={{ fontSize: 18, color: '#7C248E' }} />
                </span>
              }
              onClick={() => openTab()}
            >
              <section></section>
            </VerticalTab>
          ) : null}
        </VerticalTabs>
      </section>
    </section>
  )
}

export default ReportsContainer
